import { useState, useEffect, memo, useMemo } from "react";
import {
  Card,
  Grid,
  InputLabel,
  Checkbox,
  Autocomplete,
  Icon,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  MDBox,
  MDTypography,
  MDInput,
  MDLoadingButton,
  MDButton,
  DataTable,
  StatusCell,
} from "components";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { defaultTemplate, withTranslate } from "hoc";
import { UsersService, RolesService, PermissionsService } from "services";
import { useSnackbar, useAuth } from "helper";

const getContentHeight = () => {
  let contentHeight;
  if (window.innerWidth >= 1920) {
    contentHeight = "80vh"; // FHD resolution
  } else if (window.innerWidth < 1920 && window.innerWidth >= 1366) {
    contentHeight = "75vh"; // HD resolution
  } else {
    contentHeight = "70vh"; // Lower than HD
  }
  return contentHeight;
};

function Edit({ t }) {
  const [contentHeight, setContentHeight] = useState(getContentHeight());
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isRolesLoading, setIsRolesLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [onlyAssignedRoles, setOnlyAssignedRoles] = useState(false);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [onlyAssignedPermissions, setOnlyAssignedPermissions] = useState(false);
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [isFetchingUserInProgress, setIsFetchingUserInProgress] = useState(false);
  const { getAllTenants, getAllClients, getUserById, updateUser } = UsersService();
  const { getByQuery: getRolesByQuery } = RolesService();
  const { getByQuery: getPermissionsByQuery } = PermissionsService();
  const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      username: "",
      active: false,
      roles: [],
      permissions: [],
    },
  });
  const navigate = useNavigate();
  const { state: userId } = useLocation();
  const { user } = useAuth();

  const fetchUser = async () => {
    try {
      setIsFetchingUserInProgress(true);
      const { data, status } = await getUserById(userId);
      if (status === 200) {
        [
          "id",
          "firstname",
          "lastname",
          "email",
          "username",
          "active",
          "roles",
          "permissions",
        ].forEach((formProp) => setValue(formProp, data[formProp]));
      }
    } catch (e) {
      showErrorSnackbar({ content: "apierrorfetch" });
    } finally {
      setIsFetchingUserInProgress(false);
    }
  };

  const fetchCompanies = async () => {
    if (companies.length > 0) return;
    try {
      setIsCompaniesLoading(true);
      const { data, status } = await getAllTenants();
      if (status === 200) setCompanies(data);
    } catch (e) {
      showErrorSnackbar({ content: "apierrorfetch" });
    } finally {
      setIsCompaniesLoading(false);
    }
  };

  const fetchClients = async () => {
    if (clients.length > 0) return;
    try {
      setIsClientsLoading(true);
      const { data, status } = await getAllClients(selectedCompany.id);
      if (status === 200) setClients(data);
    } catch (e) {
      showErrorSnackbar({ content: "apierrorfetch" });
    } finally {
      setIsClientsLoading(false);
    }
  };

  const fetchRoles = async (clientId) => {
    try {
      setIsRolesLoading(true);
      setOnlyAssignedRoles(false);
      const { data, status } = await getRolesByQuery(
        { page: 1, recordsToTake: 1000 },
        { clientId }
      );
      if (status === 200) setRoles(data);
    } catch (e) {
      showErrorSnackbar({ content: "apierrorfetch" });
    } finally {
      setIsRolesLoading(false);
    }
  };

  const fetchPermissions = async (clientId) => {
    try {
      setIsPermissionsLoading(true);
      setOnlyAssignedPermissions(false);
      const { data, status } = await getPermissionsByQuery(
        { page: 1, recordsToTake: 1000 },
        { clientId }
      );
      if (status === 200) setPermissions(data);
    } catch (e) {
      showErrorSnackbar({ content: "apierrorfetch" });
    } finally {
      setIsPermissionsLoading(false);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    return handleSubmit(async ({ permissions, email, roles, ...otherProps }) => {
      try {
        setIsEditInProgress(true);
        const { status } = await updateUser({
          changeUserId: user.id,
          directPermissions: permissions.map(({ id }) => id),
          email,
          username: email,
          roles: roles.map(({ id }) => id),
          ...otherProps,
        });
        if (status === 200) {
          navigate("/users");
          showSuccessSnackbar({ content: "usermanagment.general.userupdatedsuccessfully" });
        }
      } catch (e) {
        showErrorSnackbar({ content: "apierrorpost" });
      } finally {
        setIsEditInProgress(false);
      }
    })();
  };

  useEffect(() => {
    fetchUser();
    const handleResize = () => setContentHeight(getContentHeight());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [userId]);

  const AdditionalRoleFilter = memo(() => (
    <Grid item pt={0.25} pr={2}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <InputLabel>{t("permissions.list.filteronlyassigned")}</InputLabel>
        </Grid>
        <Grid item>
          <Checkbox
            value={onlyAssignedRoles}
            checked={onlyAssignedRoles}
            onChange={({ target: { checked } }) => setOnlyAssignedRoles(checked)}
          />
        </Grid>
      </Grid>
    </Grid>
  ));

  const AdditionalPermissionFilter = memo(() => (
    <Grid item pt={0.25} pr={2}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <InputLabel>{t("permissions.list.filteronlyassigned")}</InputLabel>
        </Grid>
        <Grid item>
          <Checkbox
            value={onlyAssignedPermissions}
            checked={onlyAssignedPermissions}
            onChange={({ target: { checked } }) => setOnlyAssignedPermissions(checked)}
          />
        </Grid>
      </Grid>
    </Grid>
  ));

  const UserRoleActiveCell = memo(({ value, data }) => (
    <Checkbox
      value={value}
      defaultChecked={Boolean(getValues("roles").find((role) => role.id === value))}
      onChange={({ target: { value: roleId } }, isChecked) =>
        setValue(
          "roles",
          isChecked
            ? [...getValues("roles"), data.find((role) => role.id === roleId)]
            : getValues("roles").filter((role) => role.id !== roleId)
        )
      }
    />
  ));

  const UserPermissionActiveCell = memo(({ value, data }) => (
    <Checkbox
      value={value}
      defaultChecked={Boolean(
        getValues("permissions").find((permission) => permission.id === value)
      )}
      onChange={({ target: { value: permissionId } }, isChecked) =>
        setValue(
          "permissions",
          isChecked
            ? [
                ...getValues("permissions"),
                data.find((permission) => permission.id === permissionId),
              ]
            : getValues("permissions").filter((permission) => permission.id !== permissionId)
        )
      }
    />
  ));

  const ActiveStatusCell = memo(({ value }) => <StatusCell status={value ? 1 : 2} />);

  const filteredRoles = useMemo(
    () =>
      onlyAssignedRoles
        ? roles.filter((i) => getValues("roles").find((j) => i.id === j.id))
        : roles,
    [roles, onlyAssignedRoles]
  );

  const filteredPermissions = useMemo(
    () =>
      onlyAssignedPermissions
        ? permissions.filter((i) => getValues("permissions").find((j) => i.id === j.id))
        : permissions,
    [permissions, onlyAssignedPermissions]
  );

  return (
    <MDBox>
      {isFetchingUserInProgress ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={onSubmit}>
          <MDBox sx={{ height: contentHeight, overflowY: "auto", overflowX: "hidden" }}>
            {/* <GENERAL> */}
            <Card sx={{ padding: 5 }}>
              <MDTypography variant="h5" mb="3vh">
                {t("inputForm.general")}
              </MDTypography>
              <Grid container>
                <Grid item xs={12} md={6} pr={1}>
                  <InputLabel sx={{ height: "2vh" }}>
                    {t("usermanagment.columns.firstname")} *
                  </InputLabel>
                  <Controller
                    name="firstname"
                    control={control}
                    rules={{ required: t("usermanagment.general.nameerror") }}
                    render={({ field }) => (
                      <MDInput fullWidth type="text" variant="standard" {...field} />
                    )}
                  />
                  <MDTypography
                    component="span"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors.firstname && errors.firstname.message}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel sx={{ height: "2vh" }}>
                    {t("usermanagment.columns.lastname")} *
                  </InputLabel>
                  <Controller
                    name="lastname"
                    control={control}
                    rules={{ required: t("usermanagment.general.lastnameerror") }}
                    render={({ field }) => (
                      <MDInput fullWidth type="text" variant="standard" {...field} />
                    )}
                  />
                  <MDTypography
                    component="span"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors.lastname && errors.lastname.message}
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6} pr={1} pt={5}>
                  <InputLabel sx={{ height: "2vh" }}>
                    {t("usermanagment.columns.email")} *
                  </InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: t("usermanagment.general.emailerror"),
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: t("signin.invalidEmailFormat"),
                      },
                    }}
                    render={({ field }) => (
                      <MDInput fullWidth disabled type="text" variant="standard" {...field} />
                    )}
                  />
                  <MDTypography
                    component="span"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errors.email && errors.email.message}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} md={6} pt={5}>
                  <InputLabel sx={{ height: "2vh" }}>
                    {t("usermanagment.columns.status")}
                  </InputLabel>
                  <Controller
                    name="active"
                    control={control}
                    render={({ field }) => <Checkbox checked={field.value} {...field} />}
                  />
                </Grid>
              </Grid>
            </Card>
            {/* </GENERAL> */}
            {/* <ROLES AND PERMISSIONS> */}
            <Card sx={{ padding: 5, marginTop: 1 }}>
              <MDTypography variant="h5" mb="3vh">
                {t("inputForm.rolespermissions")}
              </MDTypography>
              <Grid container>
                <Grid item xs={12} md={6} pr={1}>
                  <Autocomplete
                    disableClearable
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    loading={isCompaniesLoading}
                    loadingText={t("autocomplete.loading")}
                    noOptionsText={t("autocomplete.nooptions")}
                    onChange={(_, value) => {
                      setSelectedCompany(value);
                      setClients([]);
                      setSelectedClient(null);
                    }}
                    onOpen={fetchCompanies}
                    options={companies}
                    renderInput={(params) => (
                      <MDInput
                        variant="standard"
                        placeholder={t("usermanagment.select.company")}
                        {...params}
                      />
                    )}
                    value={selectedCompany}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    disableClearable
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    loading={isClientsLoading}
                    loadingText={t("autocomplete.loading")}
                    noOptionsText={t("autocomplete.nooptions")}
                    onChange={(_, value) => {
                      setSelectedClient(value);
                      fetchRoles(value.id);
                      fetchPermissions(value.id);
                    }}
                    onOpen={fetchClients}
                    options={clients}
                    renderInput={(params) => (
                      <MDInput
                        variant="standard"
                        placeholder={t("usermanagment.select.client")}
                        {...params}
                      />
                    )}
                    value={selectedClient}
                  />
                </Grid>
              </Grid>
              {selectedCompany == null || selectedClient == null ? (
                <Grid
                  container
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                  pt={5}
                >
                  <Grid item>
                    <Icon fontSize="large">
                      {selectedCompany == null ? "business" : "computer"}
                    </Icon>
                  </Grid>
                  <Grid item>
                    <MDTypography variant="h6">
                      {t(`usermanagment.select.${selectedCompany == null ? "company" : "client"}`)}
                    </MDTypography>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12} md={8} pt={5} pb={5}>
                      <MDTypography variant="h6">{t("menu.roles")}</MDTypography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ height: "500px" }}>
                      {isRolesLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <DataTable
                          AdditionalFilterComponent={AdditionalRoleFilter}
                          canSearch
                          footerEnabled={false}
                          isSorted={false}
                          subtractTableHeight="7vh"
                          table={{
                            columns: [
                              {
                                id: "id",
                                accessor: "id",
                                Cell: UserRoleActiveCell,
                              },
                              {
                                Header: t("usermanagment.table.name"),
                                accessor: "name",
                              },
                              {
                                Header: t("usermanagment.table.description"),
                                accessor: "description",
                              },
                              {
                                Header: t("usermanagment.table.status"),
                                accessor: "active",
                                Cell: ActiveStatusCell,
                              },
                            ],
                            rows: filteredRoles,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container>
                    <Grid item xs={12} md={8} pt={5} pb={5}>
                      <MDTypography variant="h6">{t("menu.permissions")}</MDTypography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ height: "500px" }}>
                      {isPermissionsLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <DataTable
                          AdditionalFilterComponent={AdditionalPermissionFilter}
                          canSearch
                          footerEnabled={false}
                          isSorted={false}
                          subtractTableHeight="7vh"
                          table={{
                            columns: [
                              {
                                id: "id",
                                accessor: "id",
                                Cell: UserPermissionActiveCell,
                              },
                              {
                                Header: t("usermanagment.table.name"),
                                accessor: "name",
                              },
                              {
                                Header: t("usermanagment.table.description"),
                                accessor: "description",
                              },
                              {
                                Header: t("usermanagment.table.externalid"),
                                accessor: "externalId",
                              },
                              {
                                Header: t("usermanagment.table.status"),
                                accessor: "active",
                                Cell: ActiveStatusCell,
                              },
                            ],
                            rows: filteredPermissions,
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              <Controller name="roles" control={control} render={() => <input type="hidden" />} />
              <Controller
                name="permissions"
                control={control}
                render={() => <input type="hidden" />}
              />
            </Card>
            {/* </ROLES AND PERMISSIONS> */}
          </MDBox>
          <Grid
            container
            className="actionButtons"
            wrap="nowrap"
            sx={{ position: "absolute", bottom: 0, pb: 1 }}
          >
            <MDLoadingButton
              type="submit"
              variant="gradient"
              className="buttonSave"
              isLoading={isEditInProgress}
            >
              {t("projects.details.save")}
            </MDLoadingButton>
            <MDButton
              disableRipple
              variant="gradient"
              className="buttonCancel"
              onClick={() => navigate("/users")}
              disabled={isEditInProgress}
            >
              {t("inputForm.cancel")}
            </MDButton>
          </Grid>
        </form>
      )}
    </MDBox>
  );
}

export default defaultTemplate(withTranslate(Edit));
